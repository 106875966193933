<template>
  <!-- Page Content -->
  <div>
    <header>
      <h1 class="text-3xl font-semibold tracking-wide">Units - Reserved</h1>
      <p class="mt-2 text-md" style="margin-left: 2px">
        {{ totalUnits }} Units
      </p>
    </header>

    <!-- Unit Reserved Content -->
    <!-- Unit Reserved Table Content -->
    <table class="min-w-max w-full mt-5 table-auto">
      <!-- Unit Reserved Table Content Header -->
      <thead>
      <tr class="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
        <th
          class="py-3 px-6 text-center"
          v-if="hasAccess('units', 'edit')"
        ></th>
        <th
          @click="sort('status')"
          class="heading-text"
          :class="{ sorting: currentSort === 'status' }"
        >
          Status
          <MenuDown
            v-if="isSorted('status', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('status', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('name')"
          class="heading-text"
          :class="{ sorting: currentSort === 'name' }"
        >
          Unit
          <MenuDown
            v-if="isSorted('name', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('name', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('price')"
          class="heading-text"
          :class="{ sorting: currentSort === 'price' }"
        >
          Price
          <MenuDown
            v-if="isSorted('price', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('price', 'asc')"
            class="inline-block absolute"
          />
        </th>
<!--        <th-->
<!--            @click="sort('discountGloblePrice')"-->
<!--            class="heading-text"-->
<!--            :class="{ sorting: currentSort === 'discountGloblePrice' }"-->
<!--        >-->
<!--          Discount Globle Price-->
<!--          <MenuDown-->
<!--              v-if="isSorted('discountGloblePrice', 'desc')"-->
<!--              class="inline-block absolute"-->
<!--          />-->
<!--          <MenuUp-->
<!--              v-if="isSorted('discountGloblePrice', 'asc')"-->
<!--              class="inline-block absolute"-->
<!--          />-->
<!--        </th>-->
        <th
            @click="sort('unitParkingBay')"
            class="heading-text"
            :class="{ sorting: currentSort === 'unitParkingBay' }"
        >
          Parking Price
          <MenuDown
              v-if="isSorted('unitParkingBay', 'desc')"
              class="inline-block absolute"
          />
          <MenuUp
              v-if="isSorted('unitParkingBay', 'asc')"
              class="inline-block absolute"
          />
        </th>
        <th
            @click="sort('unitGuaranteedRentalPrice')"
            class="heading-text"
            :class="{ sorting: currentSort === 'unitGuaranteedRentalPrice' }"
        >
          Guaranteed Rental Price
          <MenuDown
              v-if="isSorted('unitGuaranteedRentalPrice', 'desc')"
              class="inline-block absolute"
          />
          <MenuUp
              v-if="isSorted('unitGuaranteedRentalPrice', 'asc')"
              class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('purchasePrice')"
          class="heading-text"
          :class="{ sorting: currentSort === 'purchasePrice' }"
        >
          Purchase Price
          <MenuDown
            v-if="isSorted('purchasePrice', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('purchasePrice', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('discountDiscount')"
          class="heading-text"
          :class="{ sorting: currentSort === 'discountDiscount' }"
        >
          Discount
          <MenuDown
            v-if="isSorted('discountDiscount', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('discountDiscount', 'asc')"
            class="inline-block absolute"
          />
        </th>
<!--        <th-->
<!--          @click="sort('discountType')"-->
<!--          class="heading-text"-->
<!--          :class="{ sorting: currentSort === 'discountType' }"-->
<!--        >-->
<!--          Discount Type-->
<!--          <MenuDown-->
<!--            v-if="isSorted('discountType', 'desc')"-->
<!--            class="inline-block absolute"-->
<!--          />-->
<!--          <MenuUp-->
<!--            v-if="isSorted('discountType', 'asc')"-->
<!--            class="inline-block absolute"-->
<!--          />-->
<!--        </th>-->
        <th
            @click="sort('paymentPaidOn')"
            class="heading-text"
            :class="{ sorting: currentSort === 'paymentPaidOn' }"
        >
          Paid On
          <MenuDown
              v-if="isSorted('paymentPaidOn', 'desc')"
              class="inline-block absolute"
          />
          <MenuUp
              v-if="isSorted('paymentPaidOn', 'asc')"
              class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('customerAgent')"
          class="heading-text"
          :class="{ sorting: currentSort === 'customerAgent' }"
        >
          Agent
          <MenuDown
            v-if="isSorted('customerAgent', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('customerAgent', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('customerFirstName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'customerFirstName' }"
        >
          Client First Name
          <MenuDown
            v-if="isSorted('customerFirstName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('customerFirstName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('customerLastName')"
          class="heading-text"
          :class="{ sorting: currentSort === 'customerLastName' }"
        >
          Client Last Name
          <MenuDown
            v-if="isSorted('customerLastName', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('customerLastName', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('customerEmail')"
          class="heading-text"
          :class="{ sorting: currentSort === 'customerEmail' }"
        >
          Client Email
          <MenuDown
            v-if="isSorted('customerEmail', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('customerEmail', 'asc')"
            class="inline-block absolute"
          />
        </th>
        <th
          @click="sort('customerContact')"
          class="heading-text"
          :class="{ sorting: currentSort === 'customerContact' }"
        >
          Client Contact
          <MenuDown
            v-if="isSorted('customerContact', 'desc')"
            class="inline-block absolute"
          />
          <MenuUp
            v-if="isSorted('customerContact', 'asc')"
            class="inline-block absolute"
          />
        </th>
      </tr>
      </thead>

      <!-- Unit Reserved Table Content Body -->
      <tbody class="text-gray-600 text-sm font-light">
      <tr
        v-for="unit in sortedUnits"
        :key="unit.id"
        class="border-b border-gray-200 hover:bg-gray-100"
      >
        <td
          class="py-3 px-6 text-left whitespace-nowrap"
          v-if="hasAccess('units', 'edit')"
        >
          <div class="flex item-center justify-center">
            <router-link
              :to="{ name: 'admin-update-unit', params: { id: unit.id } }"
              class="w-4 mr-2 transform hover:text-purple-500 hover:scale-110"
            >
              <EditIcon />
            </router-link>
          </div>
        </td>

        <td class="py-3 px-6 text-center">
            <span
              v-if="unit.status === 'available'"
              class="bg-green-200 text-green-600 py-1 px-3 rounded-full text-xs"
            >Available</span
            >

          <span
            v-if="unit.status === 'pending'"
            class="bg-gray-200 text-gray-600 py-1 px-3 rounded-full text-xs"
          >
              Pending
            </span>

          <span
            v-if="unit.status === 'reserved'"
            class="bg-blue-200 text-blue-600 py-1 px-3 rounded-full text-xs"
          >
              Reserved
            </span>

          <span
            v-if="unit.status === 'sold'"
            class="bg-red-200 text-red-600 py-1 px-3 rounded-full text-xs"
          >
              Sold
            </span>
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.name }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.price | prettyCurrency }}
        </td>
<!--        <td class="py-3 px-6 text-center">-->
<!--          {{ unit.discountGloblePrice | prettyCurrency  }}-->
<!--        </td>-->
        <td class="py-3 px-6 text-center">
          {{ unit.unitParkingBay | prettyCurrency }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.unitGuaranteedRentalPrice | prettyCurrency }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.purchasePrice | prettyCurrency }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.discountDiscount | prettyCurrency }}
        </td>
<!--        <td class="py-3 px-6 text-center">-->
<!--          {{ unit.discountType }}-->
<!--        </td>-->
        <td class="py-3 px-6 text-center">
          {{ unit.paymentPaidOn.seconds | moment("calendar") }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.customerAgent }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.customerFirstName }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.customerLastName }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.customerEmail }}
        </td>
        <td class="py-3 px-6 text-center">
          {{ unit.customerContact }}
        </td>
      </tr>
      </tbody>
    </table>

    <!-- Unit Reserved Table Content Pagination -->
    <p class="pagination-container">
      <button
        v-if="currentPage != 1"
        @click="prevPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        &lt; Prev
      </button>

      <button
        v-if="(this.currentPage*this.pageSize) < this.units.length"
        @click="nextPage"
        class="mb-3 px-2 py-1 mr-1 uppercase text-sm font-semibold text-white tracking-wider rounded outline-none bg-primary border border-solid border-primary focus:outline-none"
      >
        Next &gt;
      </button>
    </p>
  </div>
</template>

<script>
import { settingsCollection, unitsCollection, dealsCollection } from "../../firebase.js";
import isNil from "lodash/isNil";
import EditIcon from "vue-material-design-icons/PencilOutline";
import { parallel } from "async";
import permissionsMixin from "@/mixins/permissions";
import MenuDown from "vue-material-design-icons/MenuDown";
import MenuUp from "vue-material-design-icons/MenuUp";
import {APP_ADMIN_PAGINATION} from "@/Constants";
import {reverse} from "lodash";

export default {
  name: "AdminUnitsList",
  mixins: [permissionsMixin],
  components: {
    EditIcon,
    MenuDown,
    MenuUp,
  },
  // props: {
  //   units: {
  //     type: Object,
  //   },
  //   deals: {
  //     default: [],
  //   },
  //   settings: {
  //     allowReserve: false,
  //     allowDiscount: true,
  //     displaySplashScreen: true,
  //     splashScreenMessage: "",
  //     launchDate: null,
  //     interestRate: null,
  //     default: [],
  //   },
  // },
  data: () => ({
    currentSort:'name',
    currentSortDir:'asc',
    pageSize: APP_ADMIN_PAGINATION,
    currentPage:1,
    units: {
      isAircon: false,
      id: null,
      name: null,
      price: null,
      floor: null,
      internalArea: null,
      externalArea: null,
      totalArea: null,
      bedrooms: null,
      bathrooms: null,
      aspect: null,
      view: null,
      rates: null,
      levies: null,
      guaranteedRental: null,

      guaranteedRentalAmount: null,
      status: null,
      paymentPaidOn: [{
        nanoseconds: null,
        seconds: null,
      }]
    },
    settings: {
      allowReserve: false,
      allowDiscount: true,
      displaySplashScreen: true,
      splashScreenMessage: "",
      launchDate: null,
      interestRate: null
    },
  }),
  created() {
    this.getSettings();
    this.getUnits("name", false);
    parallel(
      {
        units: (callback) => {
          unitsCollection
            .where("status", "==", "reserved")
            .orderBy("name")
            .onSnapshot((snap) => {
              let units = [];

              snap.forEach((doc) => {
                units.push({
                  id: doc.id,
                  name: doc.data().name,
                  price: doc.data().price,

                  status: doc.data().status,


                  customerFirstName: doc.data().customerFirstName,
                  customerLastName: doc.data().customerLastName,
                  customerContact: doc.data().customerContact,
                  customerEmail: doc.data().customerEmail,
                  customerAgent: doc.data().customerAgent,

                  // purchasePrice: doc.data().purchasePrice || null,
                  purchasePrice: this.getPurchasePrice(doc.data()),
                  discountType: doc.data().discountType || null,
                  discountDiscount: doc.data().discountDiscount || null,
                  discountGloblePrice: doc.data().discountGloblePrice || null,

                  floor: doc.data().floor,
                  internalArea: doc.data().internalArea,
                  externalArea: doc.data().externalArea,
                  totalArea: doc.data().totalArea,
                  bedrooms: doc.data().bedrooms,
                  bathrooms: doc.data().bathrooms,
                  parking: doc.data().parking,
                  storeroom: doc.data().storeroom,
                  aspect: doc.data().aspect,
                  rates: doc.data().rates,
                  levies: doc.data().levies,
                  view: doc.data().view,

                  guaranteedRentalAmount: doc.data().guaranteedRentalAmount,
                  guaranteedRental: doc.data().guaranteedRental,

                  unitParkingBay: doc.data().unitParkingBay,
                  unitGuaranteedRentalPrice: doc.data().unitGuaranteedRentalPrice,

                  paymentPaidOn: doc.data().paymentPaidOn,

                });
              });
              callback(null, units);
            });
        },
        deals: (callback) => {
          dealsCollection
            .where("status", "==", "reserved")
            .onSnapshot((snap) => {
              let deals = [];

              snap.forEach((doc) => {
                deals.push({
                  id: doc.id,
                  unitId: doc.data().unitId,
                  customerFirstName: doc.data().customerFirstName,
                  customerLastName: doc.data().customerLastName,
                  customerContact: doc.data().customerContact,
                  customerEmail: doc.data().customerEmail,
                  customerAgent: doc.data().customerAgent,
                  price: doc.data().price || null,
                  // purchasePrice: doc.data().purchasePrice || null,
                  purchasePrice: this.getPurchasePrice(doc.data()),
                  discountType: doc.data().discountType || null,
                  discountDiscount: doc.data().discountDiscount || null,
                  discountGloblePrice: doc.data().discountGloblePrice || null,
                  status: doc.data().status,
                });
              });
              callback(null, deals);
            });
        },
      },
      (err, results) => {
        this.units = results.units;
        this.units.forEach((unit) => {
          const found = results.deals.find((item) => item.unitId === unit.id);
          if (found) {
            unit.customerFirstName = found.customerFirstName;
            unit.customerLastName = found.customerLastName;
            unit.customerContact = found.customerContact;
            unit.customerEmail = found.customerEmail;
            unit.customerAgent = found.customerAgent;
            // unit.purchasePrice = found.purchasePrice;
            // unit.discountType = this.getDiscount(found.discountType);
            unit.discountGloblePrice = found.discountGloblePrice;
          }
        });
      }
    );
  },
  computed: {
    sortedUnits:function() {
      let agents = this.units
      return agents.sort((a,b) => {
        let modifier = 1;
        if(this.currentSortDir === 'desc') modifier = -1;
        if(a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if(a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      }).filter((row, index) => {
        let start = (this.currentPage-1)*this.pageSize;
        let end = this.currentPage*this.pageSize;
        if(index >= start && index < end) return true;
      });
    },
    totalUnits: function () {
      if (!isNil(this.units)) {
        return this.units.length;
      }

      return 0;
    },
  },
  methods: {
    getPurchasePrice:function(unit) {
     const purchasePrice = (parseFloat(unit.price) - parseFloat(unit.discountDiscount)) + (parseFloat(unit.unitParkingBay) + parseFloat(unit.unitGuaranteedRentalPrice))
      return purchasePrice
    },
    getUnits(orderBy, orderByReverse) {
      // TODO: add error handling. https://firebase.google.com/docs/firestore/query-data/listen

      unitsCollection
        .where("status", "==", "reserved")
        .orderBy(orderBy)
        .onSnapshot((snap) => {
          let units = [];

          snap.forEach((doc) => {
            units.push({
              id: doc.id,
              name: doc.data().name,
              price: doc.data().price,
              // purchasePrice: doc.data().purchasePrice,
              purchasePrice: this.getPurchasePrice(doc.data()),
              discountPrice: doc.data().discountPrice,
              floor: doc.data().floor,
              unitType: doc.data().type,
              aspect: doc.data().aspect,
              view: doc.data().view,
              bedrooms: doc.data().bedrooms,
              bathrooms: doc.data().bathrooms,
              parking: doc.data().parking,
              isAircon: doc.data().isAircon,
              storeroom: doc.data().storeroom,
              internalArea: doc.data().internalArea,
              externalArea: doc.data().externalArea,
              totalArea: doc.data().totalArea,
              levies: doc.data().levies,
              rates: doc.data().rates,
              guaranteedRental: doc.data().guaranteedRental,
              reserved: doc.data().reserved,
              twentyYearBondInstalment90: doc.data().twentyYearBondInstalment90,

              mainImageURL: doc.data().mainImageURL,
              slide2ImageURL: doc.data().slide2ImageURL,
              slide3ImageURL: doc.data().slide3ImageURL,

              displayOnHomePage: doc.data().displayOnHomePage,
              isGuaranteedRental: doc.data().isGuaranteedRental,
              unitRentalGuaranteed: doc.data().unitRentalGuaranteed,
              isOverrideAction: doc.data().isOverrideAction,
              unitOverrideAction: doc.data().unitOverrideAction,
              unitOverrideActionButton: doc.data().unitOverrideActionButton,
              status: doc.data().status,
              layout: doc.data().layout,

              isOneDayOnlyDiscount: doc.data().isOneDayOnlyDiscount,
              oneDayOnlyDiscount: doc.data().oneDayOnlyDiscount,
              oneDayOnlyDiscountDateStart: doc.data().oneDayOnlyDiscountDateStart,
              oneDayOnlyDiscountDateFinish: doc.data().oneDayOnlyDiscountDateFinish,

              paymentPaidOn: doc.data().paymentPaidOn,

              discountDiscount: doc.data().discountDiscount,
              unitParkingBay: doc.data().unitParkingBay,
              unitGuaranteedRentalPrice: doc.data().unitGuaranteedRentalPrice,
              customerFirstName: doc.data().customerFirstName,
              customerLastName: doc.data().customerLastName,
              customerContact: doc.data().customerContact,
              customerEmail: doc.data().customerEmail,
              customerAgent: doc.data().customerAgent,

              discountType: doc.data().discountType,
              discountGloblePrice: doc.data().discountGloblePrice,
              guaranteedRentalAmount: doc.data().guaranteedRentalAmount,
            });
          });

          if (orderByReverse) {
            this.units = reverse(units);
          } else {
            this.units = units;
          }

          this.applyFilters();
          this.componentLoading = false;
        });
    },
    getSettings() {
      settingsCollection.doc("globalSettings").onSnapshot((doc) => {
        this.settings.interestRate = doc.data().interestRate;
        this.settings.allowReserve = doc.data().allowReserve;
        this.settings.allowDiscount = doc.data().allowDiscount;
        this.settings.displaySplashScreen = doc.data().displaySplashScreen;
        this.settings.splashScreenMessage = doc.data().splashScreenMessage;
        this.settings.launchDate = doc.data().launchDate
          ? doc.data().launchDate.toDate()
          : null;
        this.settings.hidePriceOnSold = doc.data().hidePriceOnSold || false;
        this.settings.setOneDayOnlyDiscount = doc.data().setOneDayOnlyDiscount || false;
        this.settings.allowGlobalDiscount =
          doc.data().allowGlobalDiscount || false;
        this.settings.globalDiscountAmount =
          doc.data().globalDiscountAmount || null;
        this.settings.globalDiscountType = doc.data().globalDiscountType || null;
        this.settings.discountGloblePrice = doc.data().discountGloblePrice || null;
      });
    },
    isSorted(field, direction) {
      if (this.currentSort === field && this.currentSortDir === direction) {
        return true;
      }
      return false;
    },
    sort:function(s) {
      if(s === this.currentSort) {
        this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
      }
      this.currentSort = s;
    },
    nextPage:function() {
      if((this.currentPage*this.pageSize) < this.units.length) this.currentPage++;
    },
    prevPage:function() {
      if(this.currentPage > 1) this.currentPage--;
    },
    // getDiscount(type) {
    //   if (!type) {
    //     return "No Discount";
    //   }
    //   switch (type) {
    //     case "no-discount":
    //       return "No Discount";
    //     case "global":
    //       return "Global";
    //     case "unit-specific":
    //       return "Unit Specific";
    //     case "global-unit-specific":
    //       return "Global Unit Specific";
    //   }
    // },
  },
};
</script>

<style>
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  width: 100%;
}

.sorting {
  background: #ffffff33;
}

.heading-text {
  cursor: pointer;
  text-align: center;
  padding: 0.75rem 1.5rem;
}

.heading-text:hover {
  opacity: .7;
  background: #fffff333;
}
</style>
